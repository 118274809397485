.AddZone {
  background-color: #fff;
  padding: 3.2rem;
}

.Heading {
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 2.4rem;
  color: var(--color-gray-800);
  margin-bottom: 3.2rem;
}

.AreaInput,
.NameInput,
.SelectInput {
  margin-bottom: 2.4rem;
  max-width: 61rem;
}

.SelectInput {
  max-width: 61rem;
}

.TableRadioButton {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  cursor: pointer;

  input {
    visibility: none;
    position: absolute;
    left: -999rem;
  }

  &Icon {
    position: relative;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    border: 1px solid var(--color-gray-400);
  }

  & > input:checked + &Icon {
    background: #f9f5ff;
    border-color: var(--color-primary);

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 50%;
      background: var(--color-primary);
    }
  }

  &Shape {
    &Rounded,
    &Rectangle,
    &Circle,
    &Square {
      border: 1px solid var(--color-primary);
      background: #f3781f0d;
    }
    &Rounded {
      width: 9.6rem;
      height: 3.2rem;
      border-radius: 10rem;
    }
    &Rectangle {
      width: 6.8rem;
      height: 3.2rem;
    }
    &Circle,
    &Square {
      width: 4.8rem;
      height: 4.8rem;
    }
    &Circle {
      border-radius: 50%;
    }
  }
}

.Actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;

  & > button {
    padding: 1.2rem 3.4rem;
  }

  .CancelBtn {
    background-color: transparent;
    color: var(--color-gray-600);
    border: none;
  }
}

.Area {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  align-items: flex-start;

  & > span {
    display: inline-block;
    color: var(--color-gray-600);
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.4rem;
  }
}

.AreaRaioInput {
  display: flex;
  gap: 1.2rem;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.6rem;
  color: var(--color-gray-600);
  cursor: pointer;

  input {
    visibility: hidden;
    position: absolute;
    left: -999rem;
  }

  span {
    position: relative;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    border: 1px solid var(--color-gray-400);
  }

  input:checked + span {
    border-color: var(--color-primary);
    &::after {
      content: "";
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 50%;
      background-color: var(--color-primary);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.Facilities {
  margin-bottom: 8.8rem;
  max-width: 61rem;

  & > span {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.4rem;
    color: var(--color-gray-600);
    margin-bottom: 1.6rem;
    display: inline-block;
  }

  & > ul {
    list-style: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.6rem;
  }
  .Facility,
  .FacilitySelected {
    display: inline-block;
    padding: 1.6rem 1.2rem;
    border-radius: 0.4rem;
    background-color: var(--color-gray-100);
    color: var(--color-gray-800);
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.4rem;
    cursor: pointer;
  }
  .FacilitySelected {
    color: #fff;
    background-color: var(--color-primary);
  }
}
.SelectionBtn{
  font-size: 16px;       // Sets the font size
  color: #000000;        // Sets the text color (white in this case)
  letter-spacing: 1px;   // Adds spacing between letters
  text-align: center;    // Centers the text within the button
  padding: 10px 20px;    // Adds padding around the text
  background-color: #eeecec; // Button background color
  border: none;          // Removes the border
  border-radius: 50px;    // Adds rounded corners
  margin-bottom: 10px;
  &:hover {
    background-color: #000000; // Changes background on hover
    color: #ffffff;            // Ensures text remains white
  }

  &:active {
    transform: scale(0.98); // Adds a pressed effect
  }

  &:disabled {
    background-color: #ccc;  // Disabled background color
    color: #666;             // Disabled text color
    cursor: not-allowed;     // Disabled cursor style
  }
}

