.FunctionButton {
    background-color: #eeecec;
    border-radius: 55px;
    padding: 10px;
    border: 2px solid white;
    margin-right: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.selected {
    background-color: #000000;
    color: white;
    border: 2px solid #000000;
}

.ButtonContent {
    display: flex;
    justify-content: flex-end;
}
.scrollable {
    overflow-y: auto;
    height: 500px;

    &::-webkit-scrollbar {
        width: 0;
    }

    scrollbar-width: none;
    -ms-overflow-style: none;
}