.MergeButton {
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 10px 12px;
  border: none;
  border-radius: 25px;
  background: linear-gradient(90deg, #000, #683918);
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  white-space: nowrap; 
  overflow: hidden;   
  text-overflow: ellipsis; 
  max-width: 200px;
  margin-left: 15px;

  &:hover {
    transform: scale(1.05);
  }

  .MergeIcon {
    width: 22px;
  }
}

.MergeComponent {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
}
.scrollable {
  overflow-y: auto;
  height: 500px;

  &::-webkit-scrollbar {
      width: 0;
  }

  scrollbar-width: none;
  -ms-overflow-style: none;
}