.AddTable {
  background-color: #fff;
  padding: 3.2rem;
}

.Heading {
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 2.4rem;
  color: var(--color-gray-800);
  margin-bottom: 3.2rem;
}

.SeatsInput,
.TableNumberInput,
.TableShapes {
  margin-bottom: 2.4rem;
  max-width: 61rem;
}

.SelectInput {
  margin-bottom: 8.8rem;
  max-width: 61rem;
}

.TableShapes {
  display: flex;
  gap: 2.4rem;
  margin-top: 10px;
}

.TableRadioButton {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  cursor: pointer;

  input {
    visibility: none;
    position: absolute;
    left: -999rem;
  }

  &Icon {
    position: relative;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    border: 1px solid var(--color-gray-400);
  }

  &>input:checked+&Icon {
    background: #f9f5ff;
    border-color: black;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 50%;
      background: black;
    }
  }

  &Shape {

    &Rounded,
    &Rectangle,
    &Circle,
    &Square {
      border: 1px solid black;
      background: #ffffff;
    }

    &Rounded {
      width: 9.6rem;
      height: 3.2rem;
      border-radius: 10rem;
    }

    &Rectangle {
      width: 6.8rem;
      height: 3.2rem;
    }

    &Circle,
    &Square {
      width: 4.8rem;
      height: 4.8rem;
    }

    &Circle {
      border-radius: 50%;
    }
  }
}

.Actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;

  &>button {
    padding: 1.2rem 3.4rem;
  }

  .CancelBtn {
    background-color: transparent;
    color: var(--color-gray-600);
    border: none;
  }
}

.SelectBox {
  position: relative;
  max-width: 61rem;

  &>svg {
    position: absolute;
    right: 1.6rem;
    top: 1.6rem;
    color: var(--color-gray-600);
    pointer-events: none;
  }
}

.SelectInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  width: 100%;
  border: 1px solid var(--color-gray-200);
  border-radius: 0.4rem;
  padding: 1.6rem 5rem 1.6rem 2.4rem;
  color: var(--color-gray-600);
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.6rem;
}

.SelectionBtn {
  font-size: 16px; // Sets the font size
  color: #000000; // Sets the text color (white in this case)
  letter-spacing: 1px; // Adds spacing between letters
  text-align: center; // Centers the text within the button
  padding: 10px 20px; // Adds padding around the text
  background-color: #eeecec; // Button background color
  border: none; // Removes the border
  border-radius: 50px; // Adds rounded corners
  margin-bottom: 10px;

  &:hover {
    background-color: #000000; // Changes background on hover
    color: #ffffff; // Ensures text remains white
  }

  &:active {
    transform: scale(0.98); // Adds a pressed effect
  }

  &:disabled {
    background-color: #ccc; // Disabled background color
    color: #666; // Disabled text color
    cursor: not-allowed; // Disabled cursor style
  }
}